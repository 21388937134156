import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state() {
        return {
            userData: {},
            isNewCommonName: false,
            commonNameData: JSON.parse(sessionStorage.getItem("commonNameData")) || {}
        }

    },
    mutations: {
        /**
         * @description:  用户登录信息，包含name，id之类的基础信息
         */
        userDataVuex(state, userData) {
            state.userData = userData
        },
        isNewCommonNameVuex(state, isNewCommonName) {
            state.isNewCommonName = isNewCommonName
        },
        commonNameDataVuex(state, commonNameData) {
            sessionStorage.setItem("commonNameData", JSON.stringify(commonNameData))
            state.commonNameData = commonNameData
        }
    },
    actions: {
    },
    modules: {
    }
})
