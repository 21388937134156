import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import store from './store'
import Cookies from 'js-cookie'
import './scss/element-variables.scss'
import { getAction } from "../public/serverConfig"

import './scss/all.scss'
import './assets/iconfont/iconfont.css'
import './assets/iconfont/iconfont.js'
// import 'element-ui/lib/theme-chalk/index.css';
import VueWechatTitle from 'vue-wechat-title'
Vue.config.productionTip = false
Vue.prototype.$cookies = Cookies
Vue.prototype.$getAction = getAction()

// 登录验证流程
router.beforeEach((to, from, next) => {

  // if (utils.getUrlKey("menuName") != null && sessionStorage.getItem("menuName") == null) {
  //   sessionStorage.setItem("menuName", utils.getUrlKey("menuName"))
  //   router.push({ path: utils.getUrlKey("menuName"), query: to.query })
  // }

  //登录校验流程，此段代码待优化
  if (to.meta.requireAuth) {
    if (Cookies.get("jwtToken")) {
      console.log("通过")
      next();
      return
    } else {
      if (to.path === '/login') {
        next();
        return
      } else {
        next({
          path: '/login',
        });
      }
    }
  } else {
    next();
  }
});





Vue.use(ElementUI);
Vue.use(VueWechatTitle);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


// 推送
