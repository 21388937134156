
export const getAction = () => {
    // 跳转地址
    let action = {
        api: ""
    }
    if (window.location.host === "dev-net-admin.mhzzr.com") {
        // 测试环境登入
        action.api = "http://dev-net-his-api.mhzzr.com/"; // 测试接口
    } else if (window.location.host === "net-admin.mhzzr.com") {
        // 正式环境登入
        action.api = "https://net-his-api.mhzzr.com/"; // 正式接口
    } else if (
        window.location.hostname === "localhost" ||
        window.location.hostname.indexOf("192.168") >= 0
    ) {
        // 本地自定义环境 ->可指定测试|正式环境
        action.api = ""; // 测试接口
    }


    return action
}

