import axios from "axios";
import router from '../router/index'
import Cookies from 'js-cookie'
import { Loading, Message } from 'element-ui';
import { getAction } from "../../public/serverConfig"

let loading

function startLoading() {
    loading = Loading.service({
        lock: true,
        text: '加载中……',
        background: 'rgba(0, 0, 0, 0.7)'
    })
}

function endLoading() {
    loading.close()
}
let needLoadingRequestCount = 0
export function showFullScreenLoading() {
    if (needLoadingRequestCount === 0) {
        startLoading()
    }
    needLoadingRequestCount++
}

export function tryHideFullScreenLoading() {
    if (needLoadingRequestCount <= 0) return
    needLoadingRequestCount--
    if (needLoadingRequestCount === 0) {
        endLoading()
    }
}


var instance = axios.create({
    baseURL: getAction().api,
    headers: {
        // 'Content-Type': "multipart/form-data"
    }

});
// instance.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
// http://yapi.hzmhzwj.com/mock/189
// 请求拦截
instance.interceptors.request.use(
    (config) => {
        config.headers = {
            "JWT-TOKEN": Cookies.get("jwtToken")
        }
        // token认证写在这里
        showFullScreenLoading()
        return config;
    },
    (err) => {
        Promise.reject(err);
    }
);
// 响应拦截
instance.interceptors.response.use(
    (config) => {
        if (config.data.code == 202) {
            Message({
                message: config.data.msg,
                type: 'warning'
            })
            Cookies.remove('jwtToken')
            router.push({ path: '/login' })
        }
        if (config.data.code !== 401 && config.data.code !== 200) {
            Message({
                message: config.data.msg,
                type: 'warning'
            })
        }
        tryHideFullScreenLoading()
        return config.data;
    },
    (err) => {
        Promise.reject(err);
    }
);



export default function (method, url, data = null) {
    method = method.toLowerCase()
    if (method == 'post') {
        return instance.post(url, data)
        // return instance.post(url, data)
    }
    else if (method == 'get') {
        return instance.get(url, { params: data })
    } else if (method == 'delete') {
        return instance.delete(url, { params: data })
    } else if (method == 'put') {
        return instance.put(url, data)
    } else {
        console.error('未知的method' + method)
        return
    }
}
