<template>
  <div class="menu">
    <el-container>
      <el-aside class="menu_aside" width="200px">
        <div class="menu_aside_logo">
          <img
              src="../../assets/logo.png"
              style="width: 144px; height: 46px"
          />
        </div>
        <div class="menu_aside_list">
          <el-menu
              :default-active="defaultActive"
              active-text-color="#2A2F63"
              class="el-menu-vertical-demo"
              text-color="#394359"
              @close="handleClose"
              @open="handleOpen"
              @select="handleSelect"
          >
            <div v-for="item in menuIndexData" :key="item.id">
              <el-submenu v-if="item.name == '医生管理'" index="/">
                <template slot="title">
                  <img
                      alt=""
                      class="submenuImg"
                      src="../../assets/images/ysgl.png"
                  />
                  <span>医生管理</span>
                </template>
                <el-menu-item
                    v-for="list in item.children"
                    v-show="list.name == '医生资质审核'"
                    :key="list.code"
                    index="/audit"
                >
                  <span class="tags"></span>
                  医生资质审核
                </el-menu-item>
              </el-submenu>

              <el-submenu v-if="item.name == '登录日志'" index="/log">
                <template slot="title">
                  <img
                      alt=""
                      class="submenuImg"
                      src="../../assets/images/dlrz.png"
                  />
                  <span>登录日志</span>
                </template>
                <el-menu-item
                    v-for="list in item.children"
                    v-show="list.name == '医生登录日志'"
                    :key="list.code"
                    index="/log/loginLog"
                >
                  <span class="tags"></span>
                  医生登录日志
                </el-menu-item>
              </el-submenu>
              <el-submenu
                  v-if="item.name == '审核医师&药师管理'"
                  index="/physician"
              >
                <template slot="title">
                  <img
                      alt=""
                      class="submenuImg"
                      src="../../assets/images/shys.png"
                  />
                  <span>审核医师&药师管理</span>
                </template>
                <el-menu-item
                    v-for="list in item.children"
                    v-show="list.name == '审核医师&药师注册'"
                    :key="list.code"
                    index="/physician/enroll"
                >
                  <span class="tags"></span>
                  审核医师&药师注册
                </el-menu-item>
                <el-menu-item
                    v-for="list in item.children"
                    v-show="list.name == '审核医师&药师查询'"
                    :key="list.code + '1'"
                    index="/physician/query"
                >
                  <span class="tags"></span>
                  审核医师&药师查询
                </el-menu-item>
              </el-submenu>
              <el-submenu
                  v-if="item.name == '处方审核'"
                  index="/prescriptionAudit"
              >
                <template slot="title">
                  <img
                      alt=""
                      class="submenuImg"
                      src="../../assets/images/cfsh.png"
                  />
                  <span>处方审核</span>
                </template>
                <el-menu-item
                    v-for="list in item.children"
                    v-show="list.name == '医师处方审核'"
                    :key="list.code + '1'"
                    index="/prescriptionAudit/physician"
                >
                  <span class="tags"></span>
                  医师处方审核
                </el-menu-item>
                <el-menu-item
                    v-for="list in item.children"
                    v-show="list.name == '药师处方审核'"
                    :key="list.code"
                    index="/prescriptionAudit/pharmacist"
                >
                  <span class="tags"></span>
                  药师处方审核
                </el-menu-item>
              </el-submenu>

              <el-menu-item
                  v-if="item.name == '药品管理'"
                  index="/drugs/index/commonName"
              >
                <img
                    alt=""
                    class="submenuImg"
                    src="../../assets/images/ypgl.png"
                />
                <span slot="title">药品管理</span>
              </el-menu-item>

              <el-submenu v-if="item.name == '角色权限'" index="/role">
                <template slot="title">
                  <img
                      alt=""
                      class="submenuImg"
                      src="../../assets/images/jsqx.png"
                  />
                  <span>角色权限</span>
                </template>
                <el-menu-item
                    v-for="list in item.children"
                    v-show="list.name == '角色管理'"
                    :key="list.code + '1'"
                    index="/role/roleIndex"
                >
                  <span class="tags"></span>
                  角色管理
                </el-menu-item>
                <el-menu-item
                    v-for="list in item.children"
                    v-show="list.name == '账号管理'"
                    :key="list.code"
                    index="/role/account"
                >
                  <span class="tags"></span>
                  账号管理
                </el-menu-item>
              </el-submenu>

              <el-menu-item
                  v-if="item.name == '处方开方'"
                  index="/share"
              >
                <img
                    alt=""
                    class="submenuImg"
                    src="../../assets/images/cfkf.png"
                />
                <span slot="title">处方开方</span>
              </el-menu-item>

            </div>

            <!-- <el-menu-item
              index="/prescriptionSettings/prescriptionSettingsIndex"
            >
              <img
                class="submenuImg"
                src="../../assets/images/chufang.png"
                alt=""
              />
              <span slot="title">处方及病历设置</span>
            </el-menu-item> -->
          </el-menu>
        </div>
      </el-aside>
      <el-container>
        <el-header height="104px" style="padding: 0">
          <div class="menu_header">
            当前用户：
            <span>{{ userinfoData.name }}</span>
            <div
                :class="{
                wshTags: userinfoData.status == 1,
                wtgTags: userinfoData.status == 2,
                ytgTags: userinfoData.status == 3,
              }"
                class="rzTags"
            >
              {{ userinfoData.admin_status_text }}
            </div>
            <el-divider class="fontsize20" direction="vertical"></el-divider>
            <span @click="signOutClick">退出</span>
          </div>
          <div class="menu_headerTit">
            <div
                style="
                display: inline-block;
                margin-right: 40px;
                padding: 10px 12px;
                box-sizing: border-box;
              "
            >
              <Breadcrumb/>
            </div>
            <div
                v-if="$route.path == '/drugs/index/tradeName'"
                class="headerbar_tags"
            >
              <div>
                通用名：
                <span>{{ $store.state.commonNameData.name }}</span>
              </div>
              <div>
                剂型：
                <span>{{ $store.state.commonNameData.dosage_form }}</span>
              </div>
            </div>
          </div>
        </el-header>
        <el-main class="menu_main">
          <div class="menu_main_bgcolor">
            <router-view/>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
// import {getSrc} from '../../utils/index'
import {userinfo, menuIndex} from "../../api/menu/index";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      menuActiveItem: 0,
      menuActiveList: "医生资质审核",
      userData: {
        avatar: "",
        name: "",
      },
      defaultActive: "/audit",
      userinfoData: {},
      commonNameData: {},
      menuIndexData: [],
    };
  },
  watch: {
    "$store.state.commonNameData"(val) {
      if (val) {
        console.log(val);
        this.commonNameData = val;
      }
    },
  },
  created() {
    // this.ssoPreloginAxios();
    console.log(this.$route);
    this.defaultActive = this.$route.path;
    this.userinfoAxios();
    this.menuIndexAxios();
  },
  methods: {
    menuIndexAxios() {
      this.jsmenu = [];
      this.dlrzmenu = [];
      menuIndex().then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.menuIndexData = res.data;
          console.log(this.menuIndexData)
        }
      });
    },

    signOutClick() {
      console.log("退出");
      this.$cookies.remove("jwtToken");
      this.$router.push("/login");
    },
    userinfoAxios() {
      userinfo({}).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.userinfoData = res.data;
          this.$store.commit("userDataVuex", res.data);
        }
      });
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    handleSelect(index) {
      console.log(index);
      this.$router.push(index);
    },
    ssoPreloginAxios() {
      // ssoPrelogin().then((res) => {
      //   if (res.code == 200) {
      //     this.userData = res.data;
      //     this.$store.commit("userDataVuex", res.data);
      //   }
      // });
    },

    routerLinkClick(item, list, i) {
      this.menuActiveItem = i;
      this.menuActiveList = list.name;
      this.$router.push(list.path);
    },
    arrowDownClick(item) {
      item.active = !item.active;
    },
  },
};
</script>
<style lang="scss" scoped>
.menu {
  background-color: #fff;
}

.menu_aside {
  height: 100vh;
  border-right: 1px solid #ebeef8;
  box-sizing: border-box;
  overflow-x: hidden;

  .menu_aside_logo {
    width: 100%;
    height: 64px;
    padding: 5px 28px;
    box-sizing: border-box;
    border-bottom: 1px solid #ebeef8;
  }

  .menu_aside_list {
    width: 100%;
    //height: calc(100vh - 64px);
    font-size: 16px;
    color: #394359;
    //   overflow: scroll;
  }
}

.menu_header {
  height: 64px;
  line-height: 64px;
  border-bottom: 1px solid #ebeef8;
  padding: 0 20px;
  box-sizing: border-box;
  text-align: right;
  font-size: 14px;
  color: #6a7387;

  span {
    color: #394359;
  }

  span:last-child {
    cursor: pointer;
  }

  .fontsize20 {
    font-size: 16px;
  }
}

.menu_headerTit {
  height: 40px;

  font-size: 18px;
  font-weight: 500;
}

.menu_main {
  min-width: 1230px;
  background-color: rgb(238, 241, 247);
  height: calc(100vh - 104px);
  box-sizing: border-box;

  .menu_main_bgcolor {
    background-color: #fff;
    height: 100%;
    // overflow: auto;
  }
}

.submenuImg {
  width: 16px;
  height: 16px;
  margin-right: 6px;
  vertical-align: -6%;
}

.tags {
  display: inline-block;
  position: absolute;
  width: 6px;
  height: 6px;
  border: 1px solid #9fa9bd;
  left: 22px;
  bottom: 45%;
  border-radius: 50%;
}

.rzTags {
  height: 18px;
  line-height: 18px;
  padding: 2px 12px;
  display: inline-block;
  // vertical-align: middle;
  border-radius: 20px 20px 20px 0;
  margin: 0 8px;
  color: #fff;
}

.wshTags {
  background-color: #e69a41;
}

.wtgTags {
  background-color: #de241b;
}

.ytgTags {
  background-color: #029b68;
}

.headerbar_tags {
  font-size: 14px;
  display: inline-block;

  div {
    display: inline-block;
    margin-right: 24px;
    background-color: #6a7387;
    color: #c9cada;
    padding: 2px 8px;
    box-sizing: border-box;
    border-radius: 2px;

    span {
      color: #fff;
    }
  }
}
</style>
