import Vue from 'vue'
import VueRouter from 'vue-router'
/* Layout */
import Layout from '@/views/menu/index.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/login',
    name: 'login',
    meta: {
        title: "登录",
        requireAuth: true//是否需要登录才能访问页面，true为必须登录，false为不登录就能访问
    },
    component: () => import( /* webpackChunkName: "about" */ '../views/login/index.vue')
},
    {
        path: '/',
        component: Layout,
        redirect: '/audit',
        children: [
            {
                path: 'audit',
                name: 'audit',
                meta: {
                    title: "医生资质审核",
                    requireAuth: true//是否需要登录才能访问页面，true为必须登录，false为不登录就能访问
                },
                component: () => import( /* webpackChunkName: "about" */ '../views/doctor/audit.vue'),
                children: [
                    {
                        path: 'detail',
                        name: 'detail',
                        meta: {
                            title: "认证详情",
                            requireAuth: true//是否需要登录才能访问页面，true为必须登录，false为不登录就能访问
                        },
                        component: () => import( /* webpackChunkName: "about" */ '../views/doctor/audit/index.vue'),
                    }
                ]
            }

        ]
    },
    {
        path: '/log',
        component: Layout,
        redirect: '/log/loginLog',
        children: [
            {
                path: 'loginLog',
                name: 'loginLog',
                meta: {
                    title: "医生登录日志",
                    requireAuth: true//是否需要登录才能访问页面，true为必须登录，false为不登录就能访问
                },
                component: () => import( /* webpackChunkName: "about" */ '../views/log/loginLog.vue'),
            }

        ]
    },
    {
        path: '/physician',
        component: Layout,
        redirect: '/physician/enroll',
        children: [
            {
                path: 'enroll',
                name: 'enroll',
                meta: {
                    title: "审核医师&药师注册",
                    requireAuth: true//是否需要登录才能访问页面，true为必须登录，false为不登录就能访问
                },
                component: () => import( /* webpackChunkName: "about" */ '../views/physician/enroll.vue'),
            }, {
                path: 'query',
                name: 'query',
                meta: {
                    title: "审核医师&药师查询",
                    requireAuth: true//是否需要登录才能访问页面，true为必须登录，false为不登录就能访问
                },
                component: () => import( /* webpackChunkName: "about" */ '../views/physician/query.vue'),
                children: [
                    {
                        path: 'queryDetail',
                        name: 'queryDetail',
                        meta: {
                            title: "查看详情",
                            requireAuth: true//是否需要登录才能访问页面，true为必须登录，false为不登录就能访问
                        },
                        component: () => import( /* webpackChunkName: "about" */ '../views/physician/query/index.vue'),
                    }
                ]
            }

        ]
    },

    {
        path: '/prescriptionAudit',
        component: Layout,
        redirect: '/prescriptionAudit/physician',
        children: [
            {
                path: 'physician',
                name: 'physician',
                meta: {
                    title: "医师处方审核",
                    requireAuth: true//是否需要登录才能访问页面，true为必须登录，false为不登录就能访问
                },
                component: () => import( /* webpackChunkName: "about" */ '../views/prescriptionAudit/physician.vue'),
                children: [{
                    path: "physicianDetail",
                    name: "physicianDetail",
                    meta: {
                        title: "审核详情",
                        requireAuth: true//是否需要登录才能访问页面，true为必须登录，false为不登录就能访问
                    },
                    component: () => import( /* webpackChunkName: "about" */ '../views/prescriptionAudit/physician/index.vue'),
                }]
            }, {
                path: 'pharmacist',
                name: 'pharmacist',
                meta: {
                    title: "药师处方审核",
                    requireAuth: true//是否需要登录才能访问页面，true为必须登录，false为不登录就能访问
                },
                component: () => import( /* webpackChunkName: "about" */ '../views/prescriptionAudit/pharmacist.vue'),
                children: [{
                    path: "pharmacistDetail",
                    name: "pharmacistDetail",
                    meta: {
                        title: "审核详情",
                        requireAuth: true//是否需要登录才能访问页面，true为必须登录，false为不登录就能访问
                    },
                    component: () => import( /* webpackChunkName: "about" */ '../views/prescriptionAudit/pharmacist/index.vue'),
                }]
            },

        ]
    },

    {
        path: '/drugs',
        component: Layout,
        redirect: '/drugs/index',
        children: [
            {
                path: 'index',
                name: 'index',
                meta: {
                    title: "药品管理",
                    requireAuth: true//是否需要登录才能访问页面，true为必须登录，false为不登录就能访问
                },
                component: () => import( /* webpackChunkName: "about" */ '../views/drugs/drugs.vue'),
                children: [{
                    path: "commonName",
                    name: "commonName",
                    meta: {
                        title: "通用名",
                        requireAuth: true//是否需要登录才能访问页面，true为必须登录，false为不登录就能访问
                    },
                    component: () => import( /* webpackChunkName: "about" */ '../views/drugs/commonName.vue'),
                }, {
                    path: "tradeName",
                    name: "tradeName",
                    meta: {
                        title: "药品名",
                        requireAuth: true//是否需要登录才能访问页面，true为必须登录，false为不登录就能访问
                    },
                    component: () => import( /* webpackChunkName: "about" */ '../views/drugs/tradeName.vue'),
                }]
            },

        ]
    },
    {
        path: '/prescriptionSettings',
        component: Layout,
        redirect: '/prescriptionSettings/prescriptionSettingsIndex',
        children: [
            {
                path: 'prescriptionSettingsIndex',
                name: 'prescriptionSettingsIndex',
                meta: {
                    title: "药品管理",
                    requireAuth: true//是否需要登录才能访问页面，true为必须登录，false为不登录就能访问
                },
                component: () => import( /* webpackChunkName: "about" */ '../views/prescriptionSettings/prescriptionSettings.vue'),
            },

        ]
    },

    {
        path: '/role',
        component: Layout,
        redirect: '/role/roleIndex',
        children: [
            {
                path: 'roleIndex',
                name: 'roleIndex',
                meta: {
                    title: "角色管理",
                    requireAuth: true//是否需要登录才能访问页面，true为必须登录，false为不登录就能访问
                },
                component: () => import( /* webpackChunkName: "about" */ '../views/role/role.vue'),
            }, {
                path: 'account',
                name: 'account',
                meta: {
                    title: "账号管理",
                    requireAuth: true//是否需要登录才能访问页面，true为必须登录，false为不登录就能访问
                },
                component: () => import( /* webpackChunkName: "about" */ '../views/role/account.vue'),
            },

        ]
    },
    {
        path: '/share',
        component: Layout,
        redirect: '/share/shareIndex',
        children: [
            {
                path: 'shareIndex',
                name: 'shareIndex',
                meta: {
                    title: "处方开方",
                    requireAuth: true//是否需要登录才能访问页面，true为必须登录，false为不登录就能访问
                },
                component: () => import( /* webpackChunkName: "shareIndex" */ '../views/share/share.vue'),
                children: [
                    {
                        path: 'shareDetail',
                        name: 'shareDetail',
                        meta: {
                            title: "处方详情",
                            requireAuth: true//是否需要登录才能访问页面，true为必须登录，false为不登录就能访问
                        },
                        component: () => import( /* webpackChunkName: "shareDetail" */ '../views/share/shareDetail.vue'),
                    }
                ]
            },

        ]
    },
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router
